<template>
    <div
        class="bg-[#fdfaf5] flex flex-wrap items-center flex-col min-h-[200px] mt-auto"
    >
        <div
            class="flex flex-wrap border-b border-[#dbd9ce] justify-center gap-[100px] h-[150px] pt-[20px] flex-1"
        >
            <p
                class="uppercase text-[14px] text-center text-[#8f8f8f] tracking-[3px] hover:text-red-400"
            >
                Natálie Loulová
                <br />
                <a href="tel:+420605766440"> +420 605 766 440 </a>
            </p>
            <p
                class="uppercase text-[14px] text-center text-[#8f8f8f] tracking-[3px] hover:text-red-400"
            >
                Zuzana Loulová
                <br />
                <a href="tel:+420605351127"> +420 605 351 127 </a>
            </p>
            <div class="flex flex-col">
                <p
                    class="uppercase text-[14px] text-center text-[#8f8f8f] tracking-[3px] hover:text-red-400"
                >
                    Rejčkova 1127, 269 01 Rakovník
                </p>
                <p
                    class="uppercase text-[14px] text-center text-[#8f8f8f] tracking-[3px] hover:text-red-400"
                >
                    IČO: 11979861
                </p>
            </div>
        </div>
        <div class="flex gap-[40px] justify-end flex-1 pt-[20px] pb-[40px]">
            <a
                href="https://www.instagram.com/di_flos"
                target="_blank"
                class="uppercase text-[14px] text-[#8f8f8f] tracking-[3px] hover:text-red-400"
            >
                instagram
            </a>
        </div>
    </div>
</template>
